<template>
  <b-table :items="items">
    <template #head(invoice_number)="">
      <span>No. Invoice</span>
    </template>
    <template #head(date)="">
      <span>Tgl Invoice</span>
    </template>
    <template #head(tempo_due_date)="">
      <span>Tgl Jatuh Tempo</span>
    </template>
    <template #head(amount)="">
      <span>Jumlah</span>
    </template>
    <template #cell(invoice_number)="data">
      <span>
        {{ data.item.invoice_number || "-" }}
      </span>
    </template>
    <template #cell(date)="data">
      <span>
        {{ data.item.date || "-" }}
      </span>
    </template>
    <template #cell(tempo_due_date)="data">
      <span>
        {{ data.item.tempo_due_date || "-" }}
      </span>
    </template>
    <template #cell(amount)="data">
      <span>
        {{ data.item.amount | formatAmount }}
      </span>
    </template>
  </b-table>
</template>

<script>
import { BTable } from "bootstrap-vue";

export default {
  components: {
    BTable,
  },
  props: {
    items: {
      type: "",
    },
  },
};
</script>
